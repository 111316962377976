<!--
 * @Description: 异常完成原因/拒绝原因
 * @Author: ChenXueLin
 * @Date: 2021-11-20 16:21:24
 * @LastEditTime: 2022-07-01 09:21:54
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="title"
    :visible="reasonVisible"
    width="500px"
    v-loading="dialogLoading"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    custom-class="createDispatchDialog"
  >
    <el-form :model="reasonForm">
      <el-form-item :label="reasonLabel">{{ reasonForm.reason }}</el-form-item>
      <el-form-item label="备注" v-if="reasonForm.remark">{{
        reasonForm.remark
      }}</el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "abnormalReason.",
  components: {},
  data() {
    return {};
  },
  props: {
    reasonVisible: {
      type: Boolean,
      default: false
    },
    reasonLabel: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    reasonForm: {
      type: Object,
      default: () => {
        return {
          reason: "",
          remark: ""
        };
      }
    },
    dialogLoading: {
      type: Boolean
    }
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    handleClose() {
      this.$emit("handleClose", "reasonVisible");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-dialog__body {
    padding: 20px;
  }
}
</style>
