<!--
 * @Description:带状态的详情页头部按钮行  待/未**：橙色；**中：蓝色；已**：绿色
 * @Author: ChenXueLin
 * @Date: 2021-09-03 09:42:06
 * @LastEditTime: 2022-02-22 14:12:15
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <div class="detail-title-content">
      <div class="left-content">
        <div
          :class="[
            { yellowBtn: btnType == 'yellowBtn' },
            { blueBtn: btnType == 'blueBtn' },
            { greenBtn: btnType == 'greenBtn' },
            'status-box'
          ]"
        >
          {{ btnText }}
        </div>
        <!-- 左侧button -->
        <slot name="leftButtons"></slot>
        <div class="time-box" v-show="time || showTime">
          <span class="e6-icon-time_line"></span>
          <span>下次更进时间:{{ time }}</span>
        </div>
      </div>
      <div>
        <!-- 右侧button -->
        <slot name="rightButtons"></slot>
      </div>
    </div>
    <!-- <div class="nextRow"> -->
    <!--第二行 -->
    <slot name="nextRow"></slot>
    <!-- </div> -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  props: ["btnType", "showTime", "btnText", "time"],
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>
<style lang="scss" scoped>
.detail-title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  min-height: 48px;
  padding-left: 0px;
  .left-content {
    display: flex;
    align-items: center;
    // padding-left: 160px;
    box-sizing: border-box;
    .danger-status {
      height: 24px;
      font-size: 14px;
      line-height: 24px;
      color: #ff5e74;
      padding: 0px 10px;
      box-sizing: border-box;
      border: 1px solid #f76b6c;
      border-radius: 20px;
      background: #fef4f3;
      margin-right: 5px;
    }
    .status-box {
      margin-right: 10px;
      color: #fff;
      height: 30px;
      line-height: 30px;
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      font-size: 14px;
      // background: linear-gradient(to right, #79dc89 0%, #4fb85b 100%);
    }
    .blueBtn {
      background: linear-gradient(to right, #17cdf2 0%, #1a96f8 100%);
    }
    .greenBtn {
      background: linear-gradient(to right, #79dc89 0%, #4fb85b 100%);
    }
    .yellowBtn {
      background: linear-gradient(to right, #f8a845 0%, #fd7a06 100%);
    }
    .time-box {
      margin-left: 15px;
      font-size: 14px;
      color: #474747;
      .e6-icon-time_line {
        color: #797979;
      }
    }
    .call-time {
      display: flex;
      align-items: center;
      margin-left: 10px;
      .call {
        width: 48px;
        height: 24px;
        border-radius: 20px;
        border: 1px solid #dcdfe6;
        line-height: 24px;
        text-align: center;
        font-size: 12px;
        color: rgb(96, 98, 102);
        background: #f5f7fa;
      }
      .time {
        text-align: center;
        line-height: 32px;
        font-size: 12px;
        color: rgb(72, 73, 76);
        margin-left: 10px;
      }
    }
  }
}
.nextRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
  min-height: 48px;
  .select {
    margin-right: 20px;
  }
}
.approveStatus {
  margin-right: 10px;
  color: #fff;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  // background: linear-gradient(to right, #17cdf2 0%, #1a96f8 100%);
}
</style>
